<template>
  <b-container id="app" fluid class="m-0 p-0">
    <loading-overlay :active="loadingMessage !== ''">
      <h1>{{ loadingMessage }}</h1>
    </loading-overlay>

    <div>
      <b-navbar toggleable="lg" type="light" variant="light">
        <b-navbar-brand href="#">DMS Webmail</b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item to="messages" :active="currentRoute === 'messages'">Messages</b-nav-item>
            <b-nav-item to="canned-responses-manager" :active="currentRoute === 'cannedResponsesManager'">Canned Responses</b-nav-item>
            <b-nav-item to="compose" :active="currentRoute === 'compose'">Compose</b-nav-item>
          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-form>
              <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>
              <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>
            </b-nav-form>
          </b-navbar-nav>

          <b-navbar-nav class="ml-auto" right>
            <b-nav-item @click="signOut">Sign out</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>

    <router-view v-if="ready" @set-loading-message="setLoadingMessage" />
  </b-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import loadingOverlay from 'vue-loading-overlay';
import { cache } from './data-cache';

export default {
  components: { loadingOverlay },
  data: () => ({ username: 'Admin', loadingMessage: 'Loading...', ready: false }),
  computed: {
    ...mapState(['apiKey']),
    currentRoute() {
      return this.$router.currentRoute.name;
    },
  },
  async created() {
    // console.log(this.apiKey === 'null');
    // console.log('API Check');
    if (!this.apiKey && this.$router.currentRoute.name !== 'login') {
      // console.log('Redirecting to login');
      this.$router.push({ name: 'login', query: { redirect: this.$router.currentRoute.path } });
    }
  },
  async mounted() {
    if (this.apiKey) {
      this.loadingMessage = 'Loading cached data...';
      await this.loadCachedData();
      this.loadingMessage = '';
    }
    // console.log('Ready');
    this.loadingMessage = '';
    this.ready = true;
  },
  methods: {
    ...mapMutations(['setApiKey', 'addAccount']),
    setLoadingMessage(value) {
      this.loadingMessage = value;
    },
    async loadCachedData() {
      this.cities = await cache.initialize();
    },
    async signOut() {
      await this.setApiKey({ apiKey: undefined });
      this.$router.push('/login');
    },
  },
};
</script>

<style></style>

<template>
  <b-container fluid>
    <b-row>
      <b-col cols="2" class="p-0"> </b-col>
      <b-col cols="8" class="p-0">
        <div class="pt-2">
          <email-editor v-model="responseEmail" />
        </div>
      </b-col>
      <b-col cols="2" class="p-0"> </b-col>
    </b-row>
  </b-container>
</template>
<script >
import emailEditor from './email-editor.vue';
import { cache } from '../data-cache';

export default {
  components: { emailEditor },
  data() {
    const account = cache.accounts[Math.floor(Math.random() * cache.accounts.length)];
    return {
      responseEmail: { from: account.email.toLowerCase() }
    };
  }
};
</script>
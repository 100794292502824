import mustache from 'mustache';
import { webmailApiActions } from './dms-webmail-api-client';
import { upperCaseProperties } from './helpers';

let cachedCannedResponses = undefined;

export const getCannedResponses = async () => {
  if (!cachedCannedResponses) {
    const cannedResponses = await webmailApiActions.getCannedResponses();
    cachedCannedResponses = cannedResponses.reduce((accumulator, cannedResponse) => {
      if (cannedResponse.default) {
        accumulator.defaultCannedResponse = cannedResponse;
      }
      accumulator[cannedResponse._id] = cannedResponse;
      return accumulator;
    }, {});
    if (!cachedCannedResponses.defaultCannedResponse) {
      cachedCannedResponses.defaultCannedResponse = Object.values(cachedCannedResponses)[0];
    }
  }
  return cachedCannedResponses;
};

export const renderTemplate = (template, fillData) => {
  const upperCaseTemplate = template.replace(/{{[A-Za-z0-9 ]+}}/g, match => match.toUpperCase());
  const upperCaseFillData = upperCaseProperties(fillData);
  return mustache.render(upperCaseTemplate, upperCaseFillData);
};

export const renderCannedResponse = (template, fillData) => {
  const preRender = renderTemplate(`<div style="font-size: 0.8rem;">${template}</div>`, fillData);
  return renderTemplate(preRender, upperCaseProperties(fillData));
};

export const getFilledCannedResponse = async (cannedResponseId, fillData) => {
  const cannedResponses = await getCannedResponses();
  const cannedResponse = cannedResponses[cannedResponseId];
  return renderCannedResponse(cannedResponse.template, fillData);
};

export const getFilledDefaultCannedResponse = async fillData => {
  const cannedResponses = await getCannedResponses();
  return renderCannedResponse(cannedResponses.defaultCannedResponse.template, fillData);
};

import { encodeAwardCode } from './award-code';
import { cache } from './data-cache';

export const createFillData = async (company, awardYear, account, replyToName) => {
  if (!company) {
    return {};
  }
  const { awardCity, awardState } = company;
  const { cities } = cache;
  const companyCity = cities.find(city => city.cityName.toLowerCase() === company.awardCity.toLowerCase() && city.state.toLowerCase() === company.awardState.toLowerCase()) || {
    isAlternativeCity: false,
    cityName: awardCity,
    state: awardState,
    subdomain: 'local'
  };
  const awardName = companyCity.isAlternativeCity ? `${awardCity} Award` : `Best of ${awardCity} Award`;
  const awardCode = encodeAwardCode(company.companyId, 11629, awardYear);
  const domainBase = `${companyCity.subdomain}.${account.domainName}`;
  const fillData = {
    companyName: company.companyName,
    awardCategory: company.awardCategory,
    awardCity: company.awardCity,
    awardName,
    replyToName,
    awardUrl: `https://${domainBase}/A${awardCode}`,
    purchaseUrl: `https://${domainBase}/U${awardCode}`,
    prUrl: `https://${domainBase}/P${awardCode}`,
    awardCode,
    awardYear,
    accountFirstName: account.firstName,
    accountLastName: account.lastName,
    accountEmail: account.email,
    accountDomain: account.domainName,
    domainBase: account.domainName
  };
  return fillData;
};

<template>
  <b-nav-item :active="activeSpaceTag === spaceTag" @click="updateActiveSpaceTag()">
    <slot />
    <b-badge :variant="messageCount > 0 ? 'danger' : ''">
      {{ messageCount }}
    </b-badge>
  </b-nav-item>
</template>
<script>
export default {
  props: {
    messageCount: { type: Number, required: true },
    spaceTag: { type: String, required: true },
    activeSpaceTag: { type: String, required: true }
  },

  methods: {
    updateActiveSpaceTag() {
      this.$emit('update:activeSpaceTag', this.spaceTag);
    }
  }
};
</script>

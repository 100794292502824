export const upperCaseProperties = o => {
  var newO, origKey, newKey, value;
  if (o instanceof Array) {
    return o.map(function(value) {
      if (typeof value === 'object') {
        value = upperCaseProperties(value);
      }
      return value;
    });
  } else {
    newO = {};
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newKey = origKey.toUpperCase().toString();
        value = o[origKey];
        if (value instanceof Array || (value !== null && value.constructor === Object)) {
          value = upperCaseProperties(value);
        }
        newO[newKey] = value;
      }
    }
  }
  return newO;
};

export const camelCaseProperties = o => {
  var newO, origKey, newKey, value;
  if (o instanceof Array) {
    return o.map(function(value) {
      if (typeof value === 'object') {
        value = camelCaseProperties(value);
      }
      return value;
    });
  } else {
    newO = {};
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newKey = `${origKey.slice(0, 1).toLowerCase()}${origKey.slice(1)}`;
        value = o[origKey];
        if (value instanceof Array || (value !== null && value.constructor === Object)) {
          value = camelCaseProperties(value);
        }
        newO[newKey] = value;
      }
    }
  }
  return newO;
};

import 'mutationobserver-shim';
import Vue from 'vue';
import VueRouter from 'vue-router';
import wysiwyg from 'vue-wysiwyg';
import Vuex from 'vuex';
import axios from 'axios';
import VueObserveVisibility from 'vue-observe-visibility';
import './plugins/bootstrap-vue';
import messages from './components/messages';
import login from './components/login';
import cannedResponsesManager from './components/canned-responses-manager';
import compose from './components/compose';
import app from './App.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faSync);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;
Vue.use(wysiwyg, { hideModules: { image: true } });
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(VueObserveVisibility);

const routes = [
  { path: '/canned-responses-manager', name: 'cannedResponsesManager', component: cannedResponsesManager },
  { path: '/compose', name: 'compose', component: compose },
  { path: '/login', name: 'login', component: login, meta: { public: true } },
  { path: '/', component: messages, name: 'default' },
  { path: '/messages', component: messages, name: 'messages' },
];
const router = new VueRouter({ routes });

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => !record.meta.public)) {
    if (localStorage.getItem('apiKey') === null) {
      // console.log('Redirecting to login');
      next({ path: '/login', params: { redirect: to.fullPath } });
      return;
    }
    next();
  }
  next();
});

const store = new Vuex.Store({
  state: {
    apiKey: null,
    accounts: null,
  },
  mutations: {
    setApiKey(state, { apiKey }) {
      if (!apiKey) {
        localStorage.removeItem('apiKey');
      } else {
        localStorage.setItem('apiKey', apiKey);
      }
      state.apiKey = apiKey;
      axios.defaults.headers['x-api-key'] = apiKey;
    },
    addAccount(state, { account }) {
      state.accounts[account.domainName] = account;
    },
  },
});

// console.log('v1.0.0');

const apiKey = localStorage.getItem('apiKey');

store.commit('setApiKey', { apiKey });

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

(async () => {
  new Vue({ router, store, render: (h) => h(app) }).$mount('#app');
})();

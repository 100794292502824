<template>
  <div>
    <wysiwyg v-model="content" :style="editorStyle" />
  </div>
</template>
<script>
import 'vue-wysiwyg/dist/vueWysiwyg.css';

export default {
  components: {},
  props: {
    editorStyle: { type: String, default: '' },
    value: { type: String, default: '' },
    maxHeight: { type: String, default: '10vh' }
    // setValue: { type: Function, default: value => (this.value = value) }
  },
  data() {
    return {};
  },
  computed: {
    content: {
      get() {
        return this.value;
      },
      set(content) {
        this.$emit('input', content);
      }
    }
  }
};
</script>
<style>
.ck-editor__editable {
  height: 40vh !important;
}
</style>
import axios from 'axios';
import { camelCaseProperties } from './helpers';

export const webmailApiActions = {
  patchCompany: async (companyId, companyPatch) => {
    await axios.patch(`/companies/${companyId}`, { companyPatch });
  },
  getCompanyByCompanyId: async (companyId) => {
    const { data: company } = await axios.get(`/companies/${companyId}`);
    return camelCaseProperties(company);
  },
  getActiveAccounts: async () => {
    const { data: accounts } = await axios.get('/accounts/active');
    return accounts;
  },
  patchMessageSpaceTag: async (_id, spaceTag) => {
    await axios.patch(`/messages/${_id}`, { spaceTag });
  },
  identifyMessage: async (message) => {
    const { data: result } = await axios.get(`/messages/identify/${message._id}`);
    return camelCaseProperties(result);
  },
  getSpaceMessages: async (spaceTag, skip = 0, limit = 10, sortOrder = 'LATEST_MESSAGE_DATE_ASCENDING') => {
    const { data: messages } = await axios.get(`/spaces/${spaceTag}/messages`, { params: { skip, limit, sortOrder } });
    return messages;
  },
  auth: async (username, password) => {
    const { data: loginResult } = await axios.put('/auth', { username, password });
    return loginResult;
  },
  getCannedResponses: async () => {
    const { data: cannedResponses } = await axios.get('/canned-responses');
    return cannedResponses;
  },
  insertCannedResponse: async (cannedResponse) => {
    await axios.post('/canned-responses', { cannedResponse });
  },
  deleteCannedResponse: async (cannedResponseId) => {
    await axios.delete(`/canned-responses/${cannedResponseId}`);
  },
  patchCannedResponse: async (cannedResponseId, cannedResponsePatch) => {
    await axios.patch(`/canned-responses/${cannedResponseId}`, { cannedResponsePatch });
  },
  setDefaultCannedResponse: async (cannedResponseId) => {
    await axios.patch(`/canned-responses/default/${cannedResponseId}`);
  },
  getRandomRecipient: async () => {
    const { data: recipient } = await axios.get('/recipients/random');
    return recipient;
  },
  getRandomCompany: async () => {
    const { data: company } = await axios.get('/companies/random');
    return camelCaseProperties(company);
  },
  getRandomAccount: async () => {
    const { data: account } = await axios.get('/accounts/random');
    return account;
  },
  sendMessage: async ({ toAddress, toName, fromAddress, fromName, inReplyToMessageId, referencesMessageIds, subject, text, html }) => {
    const { data: sentMessage } = await axios.post('/messages/send', {
      toAddress,
      toName,
      fromAddress,
      fromName,
      inReplyToMessageId,
      referencesMessageIds,
      subject,
      text,
      html,
    });
    return sentMessage;
  },
  getSpaceMessageCount: async (spaceTag) => {
    const { data: result } = await axios.get(`/spaces/${spaceTag}/messages/count`);
    return result.messageCount;
  },
  getAllCities: async () => {
    const { data: cities } = await axios.get('/cities/all');
    return camelCaseProperties(cities);
  },
};

<template>
  <div>
    <b-button-group>
      <messageButton v-for="space in sortedSpaces" :key="space.spaceTag" :active="!message ? false : message.spaceTag === space.spaceTag" @click="setMessageSpaceTag(space.spaceTag)">
        {{ space.displayName }}
      </messageButton>
    </b-button-group>
    <div style="font-size: 0.8rem">
      <table>
        <tr>
          <td><strong>Subject:</strong></td>
          <td>{{ subject }}</td>
        </tr>
        <tr>
          <td><strong>From:</strong></td>
          <td>{{ fromName }} [{{ fromAddress }}]</td>
        </tr>
        <tr>
          <td><strong>To:</strong></td>
          <td>{{ toName }} [{{ toAddress }}]</td>
        </tr>
        <tr>
          <td><strong>Received:</strong></td>
          <td>{{ receivedDate }}</td>
        </tr>
      </table>

      <hr />
      <div v-html="getHtml(message)"></div>
    </div>
  </div>
</template>

<script>
import sanitizeHtml from 'sanitize-html';
import messageButton from './message-button';
import moment from 'moment';

export default {
  components: { messageButton },
  props: {
    message: { type: Object, default: () => ({}) },
    spaces: { type: Object, default: () => ({}) },
  },
  computed: {
    sortedSpaces() {
      return Object.values(this.spaces).sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));
    },
    fromName() {
      return this.message.from.value[0].name;
    },
    fromAddress() {
      return this.message.from.value[0].address;
    },
    subject() {
      return this.message.subject;
    },
    toName() {
      return this.message.to.value[0].name;
    },
    toAddress() {
      return this.message.to.value[0].address;
    },
    receivedDate() {
      return  moment(this.message.date).local().format('YYYY-MM-DD hh:mm A');
    },
  },
  methods: {
    getHtml(message) {
      if (!message) {
        return 'Loading';
      }
      if (message.html) {
        const cleanHtml = sanitizeHtml(message.html, {
          allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
          allowedSchemesByTag: { img: ['data'] },
        });
        const rawLinksFixed = cleanHtml.replace(/(['"\s]*)(https?:\/\/[-a-zA-Z0-9?&@:%._+~#=/]+)(['"\s]*)/g, (fullMatch, startingChars, url, endingChars) => {
          if (startingChars.trim() === '' && endingChars.trim() === '') {
            return `<a href="${url}">${url}</a>`;
          }
          return fullMatch;
        });
        const newWindowLinks = rawLinksFixed.replace(/<a /g, '<a target="_blank" ');
        const separated = newWindowLinks.replace(/On \d{1,2}\/\d{1,2}\/\d{4} \d{1,2}:\d{1,2}:\d{1,2} [APM]{2}, .+wrote:/g, '<hr style="border: .1em solid" />$&');
        return separated;
      }
      return `<div style="white-space: pre-wrap;">${message.text}</div>`;
    },
    setMessageSpaceTag(spaceTag) {
      this.$emit('set-message-space-tag', this.message, spaceTag);
    },
  },
};
</script>
<template>
  <div v-if="ready">
    <b-container fluid>
      <b-row>
        <div>
          <b-nav tabs>
            <space-tab v-for="space in spacesSorted" :key="space.spaceTag" :active-space-tag.sync="activeSpaceTag" :message-count="space.messageCount" :space-tag="space.spaceTag">
              {{ space.displayName }}
            </space-tab>
          </b-nav>
        </div>
      </b-row>
      <b-row>
        <b-col cols="2" class="p-0">
          <div class="scrollable full-height p-1" style="background-color: #fcfcfc">
            <div>
              <b-list-group v-if="activeSpace.messages.length > 0" class="mt-2">
                <messagePreview
                  v-for="(message, messageIndex) in activeSpace.messages"
                  :key="message._id"
                  v-observe-visibility="messageIndex === activeSpace.messages.length - 1 ? { callback: lastMessageVisible, once: true } : false"
                  :message="message"
                  :active-message.sync="activeMessage"
                />
              </b-list-group>
              <div v-else class="p-5">
                <h4>No Messages</h4>
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="4" class="scrollable full-height">
          <message :message="activeMessage" :spaces="spaces" @set-message-space-tag="setMessageSpaceTag" />
        </b-col>
        <b-col cols="6">
          <email-editor v-model="responseEmail" @refresh-fill-data="refreshFillData" @archive-message="archiveMessage" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script >
import messagePreview from './message-preview';
import message from './message';
import spaceTab from './space-tab';
import emailEditor from './email-editor.vue';
import { webmailApiActions } from '../dms-webmail-api-client';
import 'vue-loading-overlay/dist/vue-loading.css';
import { forEach } from 'p-iteration';

export default {
  components: { messagePreview, spaceTab, emailEditor, message },
  data() {
    return {
      activeSpace: {},
      activeMessage: undefined,
      activeMessageIndex: 0,
      activeSpaceTag: 'UNSORTED',
      responseEmail: {},
      spaces: {
        AUTOMATION_SENT: { displayName: 'Automation Sent', displayOrder: 1000, messageCount: 0, messages: [], spaceTag: 'AUTOMATION_SENT', sortOrder: 'LATEST_MESSAGE_DATE_DESCENDING' },
        USER_SENT: { displayName: 'User Sent', displayOrder: 1001, messageCount: 0, messages: [], spaceTag: 'USER_SENT', sortOrder: 'LATEST_MESSAGE_DATE_DESCENDING' },
        UNSORTED: { displayName: 'Unsorted', displayOrder: 0, messageCount: 0, messages: [], spaceTag: 'UNSORTED', sortOrder: 'LATEST_MESSAGE_DATE_ASCENDING' },
        MORE_INFO: { displayName: 'More Info', displayOrder: 2, messageCount: 0, messages: [], spaceTag: 'MORE_INFO', sortOrder: 'LATEST_MESSAGE_DATE_ASCENDING' },
        OPT_OUT: { displayName: 'Opt-Out', displayOrder: 99, messageCount: 0, messages: [], spaceTag: 'OPT_OUT', sortOrder: 'LATEST_MESSAGE_DATE_DESCENDING' },
        ORDER_AFFECTING: { displayName: 'Order Affecting', displayOrder: 1, messageCount: 0, messages: [], spaceTag: 'ORDER_AFFECTING', sortOrder: 'LATEST_MESSAGE_DATE_DESCENDING' },
        ARCHIVED: { displayName: 'Archived', displayOrder: 100, messageCount: 0, messages: [], spaceTag: 'ARCHIVED', sortOrder: 'LATEST_MESSAGE_DATE_DESCENDING' },
        NEW_EMAIL: { displayName: 'New Email', displayOrder: 100, messageCount: 0, messages: [], spaceTag: 'NEW_EMAIL', sortOrder: 'LATEST_MESSAGE_DATE_DESCENDING' },
      },
      ready: false,
    };
  },
  computed: {
    spacesSorted() {
      return Object.values(this.spaces).sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));
    },
  },

  watch: {
    async activeSpaceTag() {
      this.setLoadingMessage('Loading messages...');
      this.activeSpace = this.spaces[this.activeSpaceTag];
      await this.loadSpaceMessages(this.activeSpaceTag, false);
      this.makeLatestMessageActive();
      this.setLoadingMessage('');
    },
    async activeMessage() {
      this.activeMessageIndex = this.activeSpace.messages.indexOf(this.activeMessage);
      return this.createResponseEmail();
    },
  },
  async beforeMount() {},
  async mounted() {
    await this.updateSpaceMessageCounts();
    this.activeSpace = this.spaces[this.activeSpaceTag];
    this.setLoadingMessage('Loading messages...');
    await this.loadSpaceMessages(this.activeSpaceTag, false);
    this.makeLatestMessageActive();
    this.setLoadingMessage('');
    this.ready = true;
  },
  methods: {
    async setLoadingMessage(value) {
      this.$emit('set-loading-message', value);
    },
    async createResponseEmail() {
      const { company, account } = await webmailApiActions.identifyMessage(this.activeMessage);
      if (!account) {
        return;
      }
      // const { from: fromHeader, 'reply-to': replyToHeader, subject } = this.activeMessage.headers;
      const replyTo = this.activeMessage.from.value[0]; // emailAddresses.parseOneAddress(replyToHeader || fromHeader);
      const replyToName = replyTo.name ? replyTo.name.split(/ /)[0] : replyTo.address;
      const replyFrom = { name: `${account.firstName} ${account.lastName}`, address: account.email.toLowerCase() };

      this.responseEmail = {
        ...this.responseEmail,
        company,
        account,
        to: replyTo.name ? `${replyTo.name} <${replyTo.address}>` : replyTo.address,
        from: replyFrom.address.toLowerCase(),
        subject: `RE: ${this.activeMessage.subject}`,
        replyToName,
        selectedCannedResponseId: '5d927058fc566180d8fd4f25',
        activeMessage: this.activeMessage,
      };
    },
    async refreshFillData() {
      return this.createResponseEmail();
    },
    async lastMessageVisible(isVisible) {
      if (isVisible) {
        await this.loadSpaceMessages(this.activeSpaceTag, true);
      }
    },
    async updateSpaceMessageCounts() {
      await forEach(Object.keys(this.spaces), async (spaceTag) => {
        this.spaces[spaceTag].messageCount = await webmailApiActions.getSpaceMessageCount(spaceTag);
      });
      setTimeout(this.updateSpaceMessageCounts, 10000);
    },
    async loadSpaceMessages(spaceTag, preserve = false, limit = 10) {
      const currentMessages = preserve ? this.spaces[spaceTag].messages : [];
      const skip = currentMessages.length;
      const sortOrder = this.spaces[spaceTag].sortOrder;
      const messages = await webmailApiActions.getSpaceMessages(spaceTag, skip, limit, sortOrder);
      this.spaces[spaceTag].messages = [...currentMessages, ...messages].filter((message) => message.spaceTag === spaceTag);
    },
    makeLatestMessageActive() {
      if (this.activeSpace) {
        const space = this.activeSpace;
        if (space.messages) {
          this.activeMessage = space.messages[0];
        }
      }
    },
    makeNextMessageActive() {
      if (this.activeSpace) {
        const space = this.activeSpace;
        if (space.messages) {
          const index = this.activeMessageIndex < space.messages.length ? this.activeMessageIndex : space.messages.length - 1;
          this.activeMessage = space.messages[index];
        }
      }
    },

    remove() {
      const index = 2;
      this.list.splice(index, 1);
    },
    setActiveTab() {
      this.makeLatestMessageActive();
    },
    async setMessageSpaceTag(message, spaceTag) {
      await webmailApiActions.patchMessageSpaceTag(message._id, spaceTag);
      const currentSpace = this.spaces[message.spaceTag];
      const newSpace = this.spaces[spaceTag];
      newSpace.messageCount = newSpace.messageCount + 1;
      currentSpace.messages = currentSpace.messages.filter((currentSpaceMessage) => {
        return currentSpaceMessage._id !== message._id;
      });
      currentSpace.messageCount = currentSpace.messageCount - 1;
      message.spaceTag = spaceTag;
      this.makeNextMessageActive();
    },
    async archiveMessage(message) {
      return this.setMessageSpaceTag(message, 'ARCHIVED');
    },
  },
};
</script>
<style lang="scss" >
// Colors
$blue: #007bff !default; // primary
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default; // danger
$orange: #fd7e14 !default;
$yellow: #ffc107 !default; // warning
$green: #28a745 !default; // success
$teal: #20c997 !default;
$cyan: #17a2b8 !default; // info

// Grays
$white: #fff !default;
$gray-100: #f8f9fa !default; // light
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default; // secondary
$gray-700: #495057 !default;
$gray-800: #343a40 !default; // dark
$gray-900: #212529 !default;
$black: #000 !default;

// .message-preview:hover {
//     background-color: $gray-200;
//     cursor: pointer;
// }

.one-line-text-block {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.full-height {
  height: calc(100vh - 100px);
}

.scrollable {
  overflow-y: scroll;
}

.scrollable::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: $gray-500;
}

.list-item {
  display: inline-block;
  width: calc(100% - 10px);
}

.list-enter-active,
.list-leave-active,
.list-move {
  transition: 500ms cubic-bezier(0.59, 0.12, 0.34, 0.95);
  transition-property: opacity, transform;
}

.list-enter {
  opacity: 0;
  transform: translateX(50px) scaleY(0.5);
}

.list-enter-to {
  opacity: 1;
  transform: translateX(0) scaleY(1);
}

.list-leave-active {
  position: absolute;
  width: calc(100% - 47px);
}

.list-leave-to {
  opacity: 0;
  transform: scaleY(0);
  transform-origin: center top;
}
</style>

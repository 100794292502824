<template>
  <b-button
    size="sm"
    :variant="active?'success':'primary'"
    class="py-0"
    style="font-size:.8em"
    @click="click()"
  >
    <slot>Button</slot>
  </b-button>
</template>
<script>
export default {
  props: { active: { type: Boolean, default: false } },
  methods: {
    click() {
      this.$emit('click');
    }
  }
};
</script>

<template>
  <div>
    <div>
      <b-button-group class="mb-1">
        <b-button
          v-for="cannedResponse in cannedResponses"
          :key="cannedResponse._id"
          :variant="!company ? 'secondary' : cannedResponse._id === selectedCannedResponseId ? 'success' : 'primary'"
          size="sm"
          class="py-0"
          style="font-size: 0.8em"
          @click="fillWithCannedResponse(cannedResponse)"
        >
          {{ cannedResponse.title }}
        </b-button>
        <!-- <b-button variant="warning" size="sm" class="py-0" style="font-size:.8em" @click="refreshFillData()">
          <font-awesome-icon icon="sync" />
        </b-button> -->
      </b-button-group>
      <b-popover v-if="company" :show.sync="companyEdit.show" target="companyLink" title="Edit" style="width: 300px">
        <label for="companyName">Company Name:</label>
        <b-form-input id="companyName" v-model="companyEdit.companyName"></b-form-input>
        <label for="awardCategory">Award Category:</label>
        <b-form-input id="awardCategory" v-model="companyEdit.awardCategory"></b-form-input>
        <b-button variant="primary" class="float-right mt-2 mb-2" size="sm" @click="saveCompanyEdit">Save</b-button>
        <b-button variant="danger" class="float-right mt-2 mr-2 mb-2" size="sm" @click="discardCompanyEdit">Discard</b-button>
      </b-popover>
      <div class="pb-2 m-0" style="font-size: 0.8rem">
        <strong>Company: </strong>

        <b-link v-if="company" id="companyLink">{{ company.companyName }}</b-link>
        <span v-else> UNKNOWN </span>
      </div>
    </div>
    <b-input-group size="sm">
      <b-input-group-text slot="prepend" style="width: 4rem"> To: </b-input-group-text>
      <b-input v-model="to" />
    </b-input-group>
    <b-input-group size="sm">
      <b-input-group-text slot="prepend" style="width: 4rem"> From: </b-input-group-text>
      <b-form-select v-model="from" :options="fromOptions"></b-form-select>
    </b-input-group>
    <b-input-group size="sm">
      <b-input-group-text slot="prepend" style="width: 4rem"> Subject: </b-input-group-text>
      <b-input v-model="subject" />
    </b-input-group>

    <rich-editor v-model="body" />
    <b-button :variant="sendButtonVariant" :disabled="sendButtonDisabled" class="mt-2" @click="send()">
      {{ sendButtonText }}
    </b-button>
    <div>{{ sendErrorMessage }}</div>
  </div>
</template>
<script>
import richEditor from './rich-editor';
import emailAddresses from 'email-addresses';
import { getCannedResponses, getFilledCannedResponse } from '../canned-responses';
import { webmailApiActions } from '../dms-webmail-api-client';
import htmlToText from 'html-to-text';
import { createFillData } from '../fill-data';
import { cache } from '../data-cache';

export default {
  components: {
    richEditor,
  },
  props: {
    value: { type: Object, default: () => {} },
  },
  data() {
    return {
      defaultCannedResponse: undefined,
      cannedResponses: [],
      sendButtonVariant: 'primary',
      sendButtonText: 'Send',
      sendButtonDisabled: false,
      sendErrorMessage: '',
      selectedCannedResponseId: '',
      companyEdit: { show: false },
    };
  },

  computed: {
    fromOptions() {
      return cache.accounts.map((account) => ({ value: account.email.toLowerCase(), html: `${account.firstName} ${account.lastName} &lt;${account.email.toLowerCase()}&gt;` }));
    },
    message() {
      return this.value.message;
    },
    activeMessage() {
      return this.value.activeMessage;
    },
    company() {
      return this.value.company;
    },
    account() {
      return this.value.account;
    },
    replyToName() {
      return this.value.replyToName;
    },
    body: {
      get() {
        return this.value.body;
      },
      set(body) {
        this.value.body = body;
        this.$emit('input', this.value);
      },
    },
    to: {
      get() {
        return this.value.to;
      },
      set(to) {
        this.value.to = to;
        this.$emit('input', this.value);
      },
    },
    from: {
      get() {
        return this.value.from;
      },
      set(from) {
        this.value.from = from;
        this.$emit('input', this.value);
      },
    },
    subject: {
      get() {
        return this.value.subject;
      },
      set(subject) {
        this.value.subject = subject;
        this.$emit('input', this.value);
      },
    },
  },
  watch: {
    async company() {
      if (!this.company) {
        return;
      }
      await this.initializeCompanyEdit();
      await this.fillBody();
    },
  },
  async mounted() {
    const cannedResponses = await getCannedResponses();
    // eslint-disable-next-line no-unused-vars
    const { defaultCannedResponse, ...nonDefaultCannedResponses } = cannedResponses;
    this.defaultCannedResponse = defaultCannedResponse;
    this.cannedResponses = Object.values(nonDefaultCannedResponses);
    await this.fillWithCannedResponse(defaultCannedResponse);
  },
  methods: {
    async updateCompanyFromDb() {
      if (this.company) {
        this.value.company = await webmailApiActions.getCompanyByCompanyId(this.company.companyId);
      }
    },
    async fillWithCannedResponse(cannedResponse) {
      await this.updateCompanyFromDb();
      this.selectedCannedResponseId = cannedResponse._id;
      this.fillBody().then(() => {
        this.$emit('input', this.value);
      });
    },
    async send() {
      this.sendButtonDisabled = true;
      this.sendButtonVariant = 'secondary';
      this.sendButtonText = 'Sending...';
      try {
        let to = emailAddresses.parseOneAddress(this.to);
        if (!to) {
          const nameEmailMatch = /^([^<]+)<([^>]+)>$/.exec(this.to);
          if (nameEmailMatch) {
            to = { address: nameEmailMatch[2].trim(), name: nameEmailMatch[1].trim() };
          } else {
            const emailMatch = /[a-zA-Z0-9.\-_+]+@[a-zA-Z0-9.\-_+]+/.exec(this.to);
            if (emailMatch) {
              to = { name: emailMatch[0].trim(), address: emailMatch[0].trim() };
            }
          }
        }
        const from = emailAddresses.parseOneAddress(this.from);
        const inReplyToMessageId = this.activeMessage ? this.activeMessage.messageId : undefined;
        const referencesMessageIds = undefined;
        const html = this.body;
        const text = htmlToText.fromString(html);

        const messageToSend = {
          toAddress: to.address,
          toName: to.name,
          fromAddress: from.address,
          fromName: from.name,
          inReplyToMessageId,
          referencesMessageIds,
          subject: this.subject,
          text,
          html,
        };

        const sentMessage = await webmailApiActions.sendMessage(messageToSend);

        if (sentMessage === 'Sent!') {
          this.sendButtonDisabled = false;
          this.sendButtonVariant = 'success';
          this.sendButtonText = 'Sent';
          setTimeout(() => {
            this.selectedCannedResponseId = this.defaultCannedResponse._id;
            this.$emit('archive-message', this.activeMessage);
          }, 2000);
        } else {
          this.sendButtonDisabled = true;
          this.sendButtonVariant = 'danger';
          this.sendButtonText = 'Send Failed';

          this.sendErrorMessage = JSON.stringify(sentMessage);
        }
      } catch (error) {
        this.sendErrorMessage = error.message;
      } finally {
        setTimeout(() => {
          this.sendButtonDisabled = false;
          this.sendButtonVariant = 'primary';
          this.sendButtonText = 'Send';
        }, 10 * 1000);
      }
    },
    async initializeCompanyEdit() {
      this.companyEdit = {
        show: this.companyEdit.show,
        companyId: this.company.companyId,
        companyName: this.company.companyName,
        awardCategory: this.company.awardCategory,
        awardState: this.company.awardState,
        awardCity: this.company.awardCity,
      };
    },
    async saveCompanyEdit() {
      await webmailApiActions.patchCompany(this.companyEdit.companyId, { companyName: this.companyEdit.companyName, awardCategory: this.companyEdit.awardCategory });
      this.companyEdit.show = false;
      await this.updateCompanyFromDb();
    },
    async discardCompanyEdit() {
      this.companyEdit.show = false;
      this.initializeCompanyEdit();
    },
    async fillBody() {
      if (!this.company || !this.account) {
        this.body = '';
        this.$emit('input', this.value);
        return;
      }

      const fillData = await createFillData(this.company, 2024, this.account, this.replyToName);

      this.body = await getFilledCannedResponse(this.selectedCannedResponseId, fillData);
      this.$emit('input', this.value);
    },
  },
};
</script>

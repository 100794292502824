const goodDigits = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789';
const baseNDigitToNumber = baseNDigit => {
  const digitPosition = goodDigits.indexOf(baseNDigit);
  if (digitPosition === -1) {
    throw new Error('Inavlid baseN');
  }
  return digitPosition;
};

const numberToBaseNDigit = number => {
  if (number > goodDigits.length) {
    throw new Error('Invalid number conversion to baseN digit');
  }
  return goodDigits[number];
};

const positiveNumberToBaseN = number => {
  if (number < goodDigits.length) {
    return numberToBaseNDigit(number);
  }
  return `${positiveNumberToBaseN(Math.floor(number / goodDigits.length))}${numberToBaseNDigit(number % goodDigits.length)}`;
};

const numberToBaseN = number => {
  if (number < 0) {
    return `-${positiveNumberToBaseN(Math.abs(number))}`;
  }
  return positiveNumberToBaseN(number);
};

const baseNToNumber = baseNValue => {
  if (!baseNValue || baseNValue === '') {
    throw new Error('Invalid baseN');
  }
  let workingValue = baseNValue.toUpperCase();

  let isNegative = false;

  if (workingValue[0] === '-') {
    workingValue = workingValue.substr(1);
    isNegative = true;
  }

  let returnValue = baseNDigitToNumber(workingValue[workingValue.length - 1]);
  for (let i = 1; i < baseNValue.length; i += 1) {
    const num = baseNDigitToNumber(workingValue[workingValue.length - (i + 1)]);
    returnValue += goodDigits.length ** i * num;
  }

  if (isNegative) {
    return returnValue * -1;
  }
  return returnValue;
};

const hyphenateCode = code => {
  return code.split('').reduce((accumulated, character, index) => {
    let accumulator = accumulated;
    switch (code.length) {
      case 1:
      case 2:
      case 3:
        break;
      case 4:
      case 5:
        if (index === 2) {
          accumulator += '-';
        }
        break;
      case 6:
        if (index === 3) {
          accumulator += '-';
        }
        break;
      case 7:
        if (index === 2 || index === 5) {
          accumulator += '-';
        }
        break;
      case 8:
        if (index === 4) {
          accumulator += '-';
        }
        break;
      case 9:
        if (index === 3 || index === 6) {
          accumulator += '-';
        }
        break;
      case 10:
      case 11:
        if (index === 3 || index === 7) {
          accumulator += '-';
        }
        break;
      case 12:
      case 13:
        if (index === 4 || index === 8) {
          accumulator += '-';
        }
        break;
      case 14:
        if (index === 5 || index === 9) {
          accumulator += '-';
        }
        break;
      case 15:
        if (index === 5 || index === 10) {
          accumulator += '-';
        }
        break;
      default:
        if (index === 4 || index === 8 || index === 12) {
          accumulator += '-';
        }
        break;
    }
    accumulator += character;
    return accumulator;
  }, '');
};

export const encodeAwardCode = (companyId, trackingGroupId, year) => {
 
  if (!companyId || !trackingGroupId || !year) {
    return undefined;
  }

  const trackingLength = numberToBaseN(trackingGroupId).length;
  const codeParts = [];
  codeParts.push(numberToBaseN(trackingLength));
  codeParts.push(numberToBaseN(trackingGroupId));
  codeParts.push(numberToBaseN(year - 2000));
  codeParts.push(numberToBaseN(companyId));
  codeParts.push(numberToBaseN(companyId % 32));
  const complexCode = codeParts.join('');
  return hyphenateCode(complexCode);
};

export const decodeAwardCode = awardCode => {
  if (!awardCode || awardCode === '') {
    return undefined;
  }
  const complexCode = awardCode.replace(/-/g, '');
  const trackingLength = baseNToNumber(complexCode.substr(0, 1));
  let isValid = trackingLength > 0 && trackingLength <= complexCode.length - 4;
  if (isValid) {
    const trackingGroupId = baseNToNumber(complexCode.substr(1, trackingLength));
    const year = baseNToNumber(complexCode.substr(trackingLength + 1, 1)) + 2000;
    const companyId = baseNToNumber(complexCode.substr(trackingLength + 2, complexCode.length - trackingLength - 3));
    const checkDigit = baseNToNumber(complexCode.substr(complexCode.length - 1));
    isValid = companyId % 32 === checkDigit;

    if (!isValid) {
      return undefined;
    }
    return { trackingGroupId, companyId, year };
  }
  return undefined;
};

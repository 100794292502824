import { webmailApiActions } from './dms-webmail-api-client';

export const cache = {
  initialize: async () => {
    await cache.refresh();
  },
  refresh: async () => {
    cache.cities = await webmailApiActions.getAllCities();
    cache.accounts = await webmailApiActions.getActiveAccounts();
  }
};

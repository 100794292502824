<template>
  <b-row align-h="center" class="mt-5">
    <b-col cols="4">
      <b-card title="DMS Webmail">
        <form @submit.prevent="login">
          <b-form-group label="Username">
            <b-input v-model="username" type="text" placeholder="Username" />
          </b-form-group>
          <b-form-group label="Password">
            <b-input v-model="password" type="password" placeholder="Password" />
          </b-form-group>
          <b-button type="submit" variant="primary" class="float-right mt-2">
            Log-in
          </b-button>
        </form>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import { mapMutations, mapState } from 'vuex';
import { webmailApiActions } from '../dms-webmail-api-client';

export default {
  data() {
    return {
      username: '',
      password: ''
    };
  },
  computed: {
    ...mapState(['baseUrl'])
  },
  methods: {
    ...mapMutations(['setApiKey']),
    async login() {
      const loginResult = await webmailApiActions.auth(this.username, this.password);
      if (loginResult) {
        if (loginResult.success) {
          this.setApiKey({ apiKey: loginResult.apiKey });
          // console.log(`Redirecting to: ${this.$route.query.redirect || '/'}`);
          this.$router.push(this.$route.query.redirect || '/');
          return;
        }
      }
      // console.log('Nope');
    }
  }
};
</script>

<style>
html,
body {
  height: 100%;
}
</style>
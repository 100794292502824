<template>
  <div>
    <b-row v-for="cannedResponse in cannedResponses" :key="cannedResponse._id" align-h="center" class="mt-5">
      <b-col cols="8">
        <form @submit.prevent="saveCannedResponse(cannedResponse)">
          <b-card>
            <b-input v-model="cannedResponse.title" style="border:0; padding:0; margin:0; font-size:1.25rem; font-weight:500;" />
            <b-row align-h="center" class="mt-0">
              <b-col cols="6">
                <h6>Template</h6>
                <b-form-textarea v-model="cannedResponse.template" no-resize rows="8" max-rows="30" />
                <b-button type="submit" variant="primary" class="float-right m-2">
                  Save
                </b-button>
                <b-button :variant="cannedResponse.default ? 'success' : 'secondary'" class="float-right m-2" @click="setDefaultCannedResponse(cannedResponse)">
                  Set As Default
                </b-button>
                <b-button variant="danger" class="float-right m-2" @click="deleteCannedResponse(cannedResponse)">
                  Delete
                </b-button>
              </b-col>
              <b-col cols="6">
                <h6>Preview</h6>
                <div v-html="createPreview(cannedResponse.template)"></div>
              </b-col>
            </b-row>
          </b-card>
        </form>
      </b-col>
    </b-row>
    <hr />

    <b-row align-h="center" class="mt-5">
      <b-col cols="4">
        <b-card title="Add new canned response">
          <form @submit.prevent="createNewCannedResponse">
            <b-form-group label="Title">
              <b-input v-model="newCannedResponseTitle" type="text" placeholder="Title" />
            </b-form-group>
            <b-form-group label="Template">
              <rich-editor v-model="newCannedResponseTemplate" />
            </b-form-group>
            <b-form-group label="Preview">
              <iframe ref="newCannedResponsePreviewIframe" style="width:100%; height:100%; maxHeight: '55vh'" frameborder="0" :srcdoc="newCannedResponsePreview" />
            </b-form-group>
            <b-button type="submit" variant="primary" style="width:100%" class="float-right mt-2">
              Add
            </b-button>
          </form>
        </b-card>
      </b-col>
      <b-col cols="4">
        <h4>Available fields:</h4>
        <table>
          <tr v-for="fillDataItem in fillDataItems" :key="fillDataItem.tag">
            <td>
              <strong>{{ fillDataItem.tag }}:</strong>
            </td>
            <td>{{ fillDataItem.sampleValue }}</td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { webmailApiActions } from '../dms-webmail-api-client';
import richEditor from './rich-editor';
import sanitizeHtml from 'sanitize-html';
import { renderCannedResponse } from '../canned-responses';
import { createFillData } from '../fill-data';

export default {
  components: {
    richEditor
  },
  data() {
    return {
      cannedResponses: [],
      newCannedResponseTitle: '',
      newCannedResponseTemplate: '',
      previewFillData: {},
      fillDataItems: [{ tag: 'COMPANYNAME', sampleValue: 'Testing' }]
    };
  },
  computed: {
    newCannedResponsePreview() {
      return this.createPreview({ template: this.newCannedResponseTemplate });
    }
  },

  async mounted() {
    this.cannedResponses = await webmailApiActions.getCannedResponses();
    const randomCompany = await webmailApiActions.getRandomCompany();
    const randomAccount = await webmailApiActions.getRandomAccount();
    this.previewFillData = await createFillData(randomCompany, new Date().getFullYear(), randomAccount, 'John');

    this.fillDataItems = Object.keys(this.previewFillData)
      .map(tag => ({ tag: tag.toUpperCase(), sampleValue: this.previewFillData[tag] }))
      .sort((a, b) => (a.tag < b.tag ? -1 : 1));
  },

  methods: {
    async saveCannedResponse(cannedResponse) {
      await webmailApiActions.patchCannedResponse(cannedResponse._id, cannedResponse);
      this.cannedResponses = await webmailApiActions.getCannedResponses();
    },
    async createNewCannedResponse() {
      const cannedResponse = { title: this.newCannedResponseTitle, template: this.newCannedResponseTemplate };
      await webmailApiActions.insertCannedResponse(cannedResponse);
      this.cannedResponses = await webmailApiActions.getCannedResponses();
    },
    createPreview(template) {
      try {
        const filledTemplate = renderCannedResponse(template, this.previewFillData);
        return sanitizeHtml(filledTemplate);
      } catch (error) {
        // console.log(template);
        // console.log(this.previewFillData);
        return `Error in template: ${error.message}`;
      }
    },
    async deleteCannedResponse(cannedResponse) {
      this.$bvModal.msgBoxConfirm('Are you sure?', { centered: true }).then(async confirmed => {
        if (confirmed) {
          await webmailApiActions.deleteCannedResponse(cannedResponse._id);
          this.cannedResponses = await webmailApiActions.getCannedResponses();
        }
      });
    },
    async setDefaultCannedResponse(cannedResponse) {
      await webmailApiActions.setDefaultCannedResponse(cannedResponse._id);
      this.cannedResponses = await webmailApiActions.getCannedResponses();
    }
  }
};
</script>

<style>
html,
body {
  height: 100%;
}
</style>
<template>
  <b-list-group-item action style="" class="p-2 mb-2" @click="updateActiveMessage(message)">
    <div class="" style="font-size: 0.9em; white-space: nowrap; text-overflow: ellipsis; overflow: hidden">
      <strong>
        <b-button :variant="message === activeMessage ? 'warning' : 'light'" class="stretched-link m-0 p-1 w-100" style="ellipsis; overflow:">{{ message.subject }}</b-button>
      </strong>
    </div>
    <div style="font-size: 0.7em; white-space: nowrap; text-overflow: ellipsis; overflow: hidden" class="mt-0 pt-0 mb-2">
      <strong>{{ dateLine(message) }}</strong> • {{ message.from.value[0].name }}
    </div>
    <div>
      <p style="font-size: 0.8em; max-height: 6em; overflow: hidden">
        {{ messagePreview(message) }}
      </p>
    </div>
  </b-list-group-item>
</template>
<script>
import moment from 'moment';
import sanitizeHtml from 'sanitize-html';
var htmlEntities = {
  nbsp: ' ',
  cent: '¢',
  pound: '£',
  yen: '¥',
  euro: '€',
  copy: '©',
  reg: '®',
  lt: '<',
  gt: '>',
  quot: '"',
  amp: '&',
  apos: "'",
};
const unescapeHtml = (html) =>
  html.replace(/&([^;]+);/g, function (entity, entityCode) {
    var match;

    if (entityCode in htmlEntities) {
      return htmlEntities[entityCode];
      /*eslint no-cond-assign: 0*/
    } else if ((match = entityCode.match(/^#x([\da-fA-F]+)$/))) {
      return String.fromCharCode(parseInt(match[1], 16));
      /*eslint no-cond-assign: 0*/
    } else if ((match = entityCode.match(/^#(\d+)$/))) {
      return String.fromCharCode(~~match[1]);
    } else {
      return entity;
    }
  });

export default {
  props: {
    activeMessage: { type: Object, required: true },
    message: { type: Object, default: () => {} },
  },
  computed: {
    sortedMessages() {
      return [...this.messages].sort((a, b) => (a.date > b.date ? -1 : 1));
    },
  },
  methods: {
    formatDate(value) {
      return moment(value).fromNow();
    },
    dateLine(message) {
      return `${this.formatDate(message.date)} `;
    },
    updateActiveMessage(message) {
      this.$emit('update:activeMessage', message);
    },
    messagePreview(message) {
      const cleanHtml = sanitizeHtml(message.html, {
        allowedTags: ['br'],
      });
      return unescapeHtml(cleanHtml).replace(/<br \/>/g,' ');
    },
  },
};
</script>
